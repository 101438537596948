// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-docs-changelog-mdx": () => import("../../src/docs/changelog.mdx" /* webpackChunkName: "component---src-docs-changelog-mdx" */),
  "component---src-docs-get-started-mdx": () => import("../../src/docs/get-started.mdx" /* webpackChunkName: "component---src-docs-get-started-mdx" */),
  "component---src-docs-index-mdx": () => import("../../src/docs/index.mdx" /* webpackChunkName: "component---src-docs-index-mdx" */),
  "component---src-docs-child-themes-index-mdx": () => import("../../src/docs/child-themes/index.mdx" /* webpackChunkName: "component---src-docs-child-themes-index-mdx" */),
  "component---src-docs-chiara-mdx": () => import("../../src/docs/chiara.mdx" /* webpackChunkName: "component---src-docs-chiara-mdx" */),
  "component---src-docs-child-themes-sima-headphones-theme-mdx": () => import("../../src/docs/child-themes/sima-headphones-theme.mdx" /* webpackChunkName: "component---src-docs-child-themes-sima-headphones-theme-mdx" */),
  "component---src-docs-child-themes-sima-furniture-theme-mdx": () => import("../../src/docs/child-themes/sima-furniture-theme.mdx" /* webpackChunkName: "component---src-docs-child-themes-sima-furniture-theme-mdx" */),
  "component---src-docs-child-themes-sima-beauty-theme-mdx": () => import("../../src/docs/child-themes/sima-beauty-theme.mdx" /* webpackChunkName: "component---src-docs-child-themes-sima-beauty-theme-mdx" */),
  "component---src-docs-child-themes-wholesale-theme-mdx": () => import("../../src/docs/child-themes/wholesale-theme.mdx" /* webpackChunkName: "component---src-docs-child-themes-wholesale-theme-mdx" */),
  "component---src-docs-customization-banner-sizes-mdx": () => import("../../src/docs/customization/banner-sizes.mdx" /* webpackChunkName: "component---src-docs-customization-banner-sizes-mdx" */),
  "component---src-docs-customization-brands-page-mdx": () => import("../../src/docs/customization/brands-page.mdx" /* webpackChunkName: "component---src-docs-customization-brands-page-mdx" */),
  "component---src-docs-customization-category-page-mdx": () => import("../../src/docs/customization/category-page.mdx" /* webpackChunkName: "component---src-docs-customization-category-page-mdx" */),
  "component---src-docs-customization-custom-popups-mdx": () => import("../../src/docs/customization/custom-popups.mdx" /* webpackChunkName: "component---src-docs-customization-custom-popups-mdx" */),
  "component---src-docs-faqs-mdx": () => import("../../src/docs/faqs.mdx" /* webpackChunkName: "component---src-docs-faqs-mdx" */),
  "component---src-docs-customization-footer-mdx": () => import("../../src/docs/customization/footer.mdx" /* webpackChunkName: "component---src-docs-customization-footer-mdx" */),
  "component---src-docs-customization-header-mdx": () => import("../../src/docs/customization/header.mdx" /* webpackChunkName: "component---src-docs-customization-header-mdx" */),
  "component---src-docs-customization-home-page-mdx": () => import("../../src/docs/customization/home-page.mdx" /* webpackChunkName: "component---src-docs-customization-home-page-mdx" */),
  "component---src-docs-customization-instant-load-mdx": () => import("../../src/docs/customization/instant-load.mdx" /* webpackChunkName: "component---src-docs-customization-instant-load-mdx" */),
  "component---src-docs-customization-layout-mdx": () => import("../../src/docs/customization/layout.mdx" /* webpackChunkName: "component---src-docs-customization-layout-mdx" */),
  "component---src-docs-customization-index-mdx": () => import("../../src/docs/customization/index.mdx" /* webpackChunkName: "component---src-docs-customization-index-mdx" */),
  "component---src-docs-customization-product-page-mdx": () => import("../../src/docs/customization/product-page.mdx" /* webpackChunkName: "component---src-docs-customization-product-page-mdx" */),
  "component---src-docs-customization-sidebar-mdx": () => import("../../src/docs/customization/sidebar.mdx" /* webpackChunkName: "component---src-docs-customization-sidebar-mdx" */),
  "component---src-docs-customization-main-navigation-mdx": () => import("../../src/docs/customization/main-navigation.mdx" /* webpackChunkName: "component---src-docs-customization-main-navigation-mdx" */),
  "component---src-docs-customization-top-banner-mdx": () => import("../../src/docs/customization/top-banner.mdx" /* webpackChunkName: "component---src-docs-customization-top-banner-mdx" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

